import React, { useState } from "react";
import { Badge } from "../General";
import styles from "./EmailsMenuItem.module.css";

const EmailsMenuItem: React.FC<{
  className?: string;
  text: string;
  onClick?: (value: string) => void;
  number?: number;
  searchParams?: URLSearchParams;
}> = ({ className = "", text, number, onClick, searchParams }) => {
  const [active, setActive] = useState<boolean>(
    searchParams?.has(text) ?? false
  );
  return (
    <div
      className={`${styles["unassigned-tickets"]} ${className} ${
        active && styles.active
      }`}
      onClick={() => {
        if (onClick) onClick(text);
        setActive((prev) => {
          return !prev;
        });
      }}
    >
      <div className={styles["text-wrapper"]}>{text}</div>
      <Badge className={styles["component-1"]} number={number} />
    </div>
  );
};

export default EmailsMenuItem;
