import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  Checkbox,
  TablePagination,
  Table,
  TableRow,
} from "@mui/material";
import {
  stableSort,
  getComparator,
  renderStatus,
  renderDetailsButton,
} from "../helpers/tableHelpers";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { createSearchParams, useNavigate } from "react-router-dom";

import { Data, Order } from "./Table.types";
import EnhancedTableHead from "./EnhancedTableHead";
interface EnhancedTableProps {
  rows: any[];
  filters: string[];
}
const EnhancedTable: React.FC<EnhancedTableProps> = ({ rows, filters }) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("status");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [filteredRows, setFilteredRows] = useState<Data[]>(rows);

  const navigate = useNavigate();
  useEffect(() => {
    setPage(0);
    if (filters.length === 0) {
      return setFilteredRows(rows);
    }
    setFilteredRows(
      rows.filter((row) => {
        return filters.includes(row.status.split(" ").pop());
      })
    );
  }, [filters, rows]);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  return (
    <Box sx={{ width: "100%", height: "inherit" }}>
      <Paper
        sx={{
          width: "100%",
          height: "inherit",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer
          sx={{ width: "100%", height: "-webkit-fill-available" }}
        >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows.length}
            />
            <TableBody>
              {visibleRows.map((row: any, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.id}
                    </TableCell>
                    <TableCell align="left">{row.subject}</TableCell>
                    <TableCell align="right">{row.assignedTo}</TableCell>
                    <TableCell align="center">
                      {renderStatus(row.status)}
                    </TableCell>
                    <TableCell align="center">{row.createdAt}</TableCell>
                    <TableCell align="center">{row.lastModified}</TableCell>
                    <TableCell align="center">
                      {renderDetailsButton(row, () => {
                        navigate({
                          pathname: `/email/${row.id}`,
                          search: createSearchParams({
                            subject: row.subject,
                          }).toString(),
                        });
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 30, 50]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ minHeight: "52px" }}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
};

export default EnhancedTable;
