import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import { Container, Modal, SplitButton, Loader } from "../components/General";
import {
  EmailThreadAccordion,
  ParamsContainer,
} from "../components/MessagesPage";
import styles from "./MessagesPage.module.css";
import { fetchEmail, updateEmailStatus } from "../firebase/firestore/emails";
import {
  sendEmail,
  regenerateResponse,
  RegeneratePromptType,
} from "../helpers/queries";
interface UserEmailType {
  date?: string;
  sender?: string;
  suggestedRemediation?: string;
  suggestedResponse?: string;
  summary?: string;
}

const MessagesPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const generateRef = useRef<HTMLDivElement>(null);
  const [selectedEmail, setSelectedEmail] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingResponse, setLoadingResponse] = useState<boolean>(false);
  const [loadingResponseType, setLoadingResponseType] =
    useState<boolean>(false);
  const [lastUserEmail, setLastUserEmail] = useState<UserEmailType>({});
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      fetchEmail(id).then((res) => {
        if (res) {
          setLastUserEmail(
            res?.emails
              .filter(
                (email: any) => email.sender !== "EasyJet Customer Support"
              )
              .pop()
          );
          setSelectedEmail(res);
        }
      });
    }
  }, [id]);

  const onSend = () => {
    const bodyHtml = generateRef.current?.innerHTML.replaceAll("\n", "<br>");
    const regex = /<([^>]+)>/;
    const match = regex.exec(lastUserEmail?.sender ?? "");
    const email = match ? match[1] : null;
    if (bodyHtml && email && selectedEmail.id) {
      // console.log(bodyHtml);
      setLoading(true);
      sendEmail({
        to: email,
        subject: selectedEmail.subject,
        queryID: selectedEmail.id,
        textContent: bodyHtml,
      })
        .then((res) => {
          navigate("/");
          setLoading(false);
        })
        .catch((e) => {
          console.log("Error sending message");
          setLoading(false);
        });
    }
  };
  const onCloseCase = async () => {
    if (selectedEmail.id) {
      await updateEmailStatus(selectedEmail.id, "Closed");
      navigate("/");
    }
  };
  const toggleModal = () => setOpenModal((prev) => !prev);

  const onRegenerate = async (
    event: React.MouseEvent<unknown> | null,
    type?: RegeneratePromptType
  ) => {
    if (selectedEmail.id) {
      if (type) {
        setLoadingResponseType(true);
      } else {
        setLoadingResponse(true);
      }
      const response = await regenerateResponse({
        queryID: selectedEmail.id,
        type,
      });
      if (response) {
        generateRef.current!.innerHTML = response;
      }
      // console.log(response);
      if (type) {
        setLoadingResponseType(false);
      } else {
        setLoadingResponse(false);
      }
    }
  };
  return (
    <div className={styles["main-menu"]}>
      <Modal
        open={openModal}
        handleClose={toggleModal}
        onConfirm={onCloseCase}
        subtitle="Are you sure you want to close this case?"
      />
      {loading && <Loader />}
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div>
            Request ID: {id}
            <br />
            Subject: {searchParams.get("subject")}
          </div>
          <Button
            variant="contained"
            className={styles.titleButton}
            onClick={toggleModal}
          >
            Close
          </Button>
        </div>
        <div className={styles.gridContainer}>
          <Container title="Thread history">
            <EmailThreadAccordion emails={selectedEmail.emails} />
          </Container>
          <div className={styles.secondColumn}>
            <Container title={`GPT Summary`}>
              <div
                className={styles.text}
                style={{
                  minHeight: 0,
                }}
              >
                {lastUserEmail?.summary}
              </div>
              <div className={styles.buttonsContainer}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    color: "var(--main-color)",
                    borderColor: "var(--main-color)",
                    fontSize: "11px",
                  }}
                  disabled={loadingResponse || loadingResponseType}
                  onClick={onRegenerate}
                >
                  {loadingResponse && (
                    <span className={styles["button--loading"]}></span>
                  )}
                  <span
                    style={{
                      color: loadingResponse ? "transparent" : "inherit",
                    }}
                  >
                    Regenerate from scratch
                  </span>
                </Button>
              </div>
            </Container>

            <Container title="Generated output">
              <div
                style={{
                  fontSize: "13px",
                  lineHeight: "20px",
                  height: "100%",
                  whiteSpace: "pre-wrap",
                  overflowY: "auto",
                  minHeight: 0,
                }}
                ref={generateRef}
                // onBlur={() =>
                //   generateRef.current?.removeAttribute("contentEditable")
                // }
              >
                {lastUserEmail?.suggestedResponse?.replaceAll("<br>", "\n")}
              </div>
              <div
                className={`${styles.buttonsContainer} ${styles.multipleChild}`}
              >
                <SplitButton
                  options={[
                    "More verbose",
                    "Less verbose",
                    "More formal",
                    "More informal",
                  ]}
                  fixedText="Generate with: "
                  clickHandler={onRegenerate}
                  loading={loadingResponseType}
                  disabled={loadingResponse || loadingResponseType}
                />
                <div style={{ display: "flex", gap: "16px" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      color: "var(--main-color)",
                      borderColor: "var(--main-color)",
                      fontSize: "11px",
                    }}
                    onClick={() => {
                      generateRef.current?.setAttribute(
                        "contentEditable",
                        "true"
                      );
                      generateRef.current?.focus();
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      color: "var(--main-color)",
                      borderColor: "var(--main-color)",
                      fontSize: "11px",
                    }}
                    onClick={onSend}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <ParamsContainer
        key={selectedEmail.bookingRef}
        bookingReference={selectedEmail?.bookingRef}
        departureAirport={selectedEmail?.departureAirport}
        destinationAirport={selectedEmail?.destinationAirport}
        issueType={selectedEmail?.issueType}
        suggestedRemediation={lastUserEmail?.suggestedRemediation || ""}
      />
    </div>
  );
};

export default MessagesPage;
