import { createContext } from "react";
import { STATUS } from "../models/types";

interface SelectedEmailProps {
  bookingRef: string;
  createdAt: Date;
  customerProblem: string;
  departureAirport: string;
  destinationAirport: string;
  easyjetEmployees: string;
  flightNumber: string;
  issueType: string;
  lastModified: Date;
  piiSummary: string;
  status: STATUS;
  subject: string;
  emails: any[];
}
interface EmailContextProps {
  list: any[];
  // selectedEmail: SelectedEmailProps | null;
  // editEmailHandler: (email?: SelectedEmailProps) => void;
}

const EmailListContext = createContext<EmailContextProps>({
  list: [],
  // selectedEmail: null,
  // editEmailHandler: () => {},
});

export default EmailListContext;
