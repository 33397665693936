import {  signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../config"
export const signIn = async(email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    console.log(user)
    return user;
    // ...
  })
  .catch((error) => {
    // const errorCode = error.code;
    // const errorMessage = error.message;
  });

}

export const logOut = async() => {
  return signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
}
