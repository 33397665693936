import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface EmailThreadAccordionProps {
  emails: any[];
}
const EmailThreadAccordion: React.FC<EmailThreadAccordionProps> = ({
  emails = [],
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div style={{ overflowY: "auto", fontSize: "inherit" }}>
      {emails.map((email, index) => {
        if (email.body) {
          return (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "text.secondary",
                      width: "100%",
                      fontSize: "13px",
                      paddingBottom: "5px",
                    }}
                  >
                    {email.date
                      .toDate()
                      .toLocaleString("en-GB", { timeZone: "UTC" })}
                  </Typography>
                  <Typography
                    sx={{
                      flexShrink: 0,
                      fontSize: "15px",
                      width: "100%",
                    }}
                  >
                    {email.sender}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: "13px",
                  }}
                >
                  {email?.body?.replaceAll("<br>", "\n")}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        }
        // return <></>;
      })}
    </div>
  );
};

export default EmailThreadAccordion;
