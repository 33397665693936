import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./App.css";
import { Modal } from "./components/General";
import EmailListContext from "./store/EmailsContext";
import { TopMenu, SideMenu } from "./components/Navigation";
import Router from "./routes/Router";
import { fetchEmailIds } from "./firebase/firestore/emails";

const App = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const toggleModal = () => setOpenModal((prev) => !prev);
  const location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/") {
      fetchEmailIds().then((emailList: any) => setList(emailList));
    }
  }, [location]);

  const onConfirm = () => {
    navigate("/");
    toggleModal();
  };
  return (
    <EmailListContext.Provider value={{ list: list }}>
      <Modal
        open={openModal}
        handleClose={toggleModal}
        onConfirm={onConfirm}
        subtitle="Are you sure you want to navigate away from this page without saving?"
      />
      <TopMenu toggleModal={toggleModal} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "-webkit-fill-available",
          paddingTop: "60px",
          width: "-webkit-fill-available",
        }}
      >
        {!location.pathname.includes("login") && (
          <SideMenu toggleModal={toggleModal} />
        )}
        <Router />
      </div>
    </EmailListContext.Provider>
  );
};

export default App;
