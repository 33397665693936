import React, { useContext } from "react";
import { EmailsMenu } from "../components/EmailsMenuPage";
import styles from "./EmailsMenuPage.module.css";
import { Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import EnhancedTable from "../components/General/Table/Table";
import EmailListContext from "../store/EmailsContext";

const EmailsMenuPage = () => {
  const [searchParams] = useSearchParams();
  const { list: rows } = useContext(EmailListContext);
  return (
    <div className={styles.container}>
      <EmailsMenu rows={rows} />
      <div
        className={styles["contentOuterContainer"]}
        style={{ left: "400px" }}
      >
        <div className={styles.contentInnerContainer}>
          <div className={styles.fillHeight}>
            <div className={styles["textContainer"]}>All emails</div>
            <div className={styles["textContainer"]}>
              <Button
                variant="outlined"
                size="medium"
                style={{
                  color: "var(--main-color)",
                  borderColor: "var(--main-color)",
                }}
              >
                + Add filter
              </Button>
            </div>
            <div className={styles.gridContainer}>
              <div
                style={{
                  padding: "10px 20px",
                  height: "-webkit-fill-available",
                }}
              >
                {/* <div className="">{rows.length} emails</div> */}
                <div className={styles.gridInnerContainer}>
                  <EnhancedTable
                    rows={rows}
                    filters={Array.from(searchParams.keys())}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailsMenuPage;
