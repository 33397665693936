import React from "react";
import { TextField, Button } from "@mui/material";
import styles from "./ParamsContainer.module.css";

interface ParamsContainerProps {
  bookingReference?: string;
  departureAirport?: string;
  destinationAirport?: string;
  issueType?: string;
  suggestedRemediation?: string;
}
const ParamsContainer: React.FC<ParamsContainerProps> = ({
  bookingReference = "",
  departureAirport = "",
  destinationAirport = "",
  issueType = "",
  suggestedRemediation = "",
}) => {
  return (
    <div className={styles.parametersContainer}>
      <span className={styles.titleText}>Extracted parameters</span>
      <div className={styles.upperButtonsContainer}>
        <TextField
          id="surname"
          label="Surname"
          defaultValue=""
          variant="standard"
          sx={{
            "& label.Mui-focused": { color: "var(--main-color)" },
            "& .MuiInput-underline": {},
          }}
        />
        <TextField
          id="bookingRef"
          label="Booking Reference"
          defaultValue={bookingReference}
          variant="standard"
          sx={{
            "& label.Mui-focused": { color: "var(--main-color)" },
            "& .MuiInput-underline": {},
          }}
        />
        <Button
          variant="outlined"
          size="small"
          style={{
            color: "var(--main-color)",
            borderColor: "var(--main-color)",
            fontSize: "11px",
          }}
          onClick={() => console.log(bookingReference)}
        >
          Request info
        </Button>
      </div>
      <div className={styles.lowerButtonsContainer}>
        <div className={styles.paramContainer}>
          <div>Departure Airport</div>
          <input
            className={styles.inputBox}
            placeholder="Enter Departure Airport"
            defaultValue={departureAirport || ""}
          />
        </div>
        <div className={styles.paramContainer}>
          <div>Destination Airport</div>
          <input
            className={styles.inputBox}
            placeholder="Enter Destination Airport"
            defaultValue={destinationAirport}
          />
        </div>
        <div className={styles.paramContainer}>
          <div>Issue type</div>
          <input
            className={styles.inputBox}
            placeholder="Enter issue type"
            defaultValue={issueType}
          />
        </div>
        <div className={styles.paramContainer} style={{ height: "100%" }}>
          <div>Recommended resolution</div>
          <textarea
            // className={styles.inputBox}
            placeholder="Enter recommended resolution"
            defaultValue={suggestedRemediation}
          />
        </div>
      </div>
    </div>
  );
};

export default ParamsContainer;
