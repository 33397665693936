import React from "react";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import EmailsMenuItem from "./EmailsMenuItem";
import { STATUS } from "../../models/types";

import styles from "./EmailsMenu.module.css";

interface EmailsMenuProps {
  rows: any[];
}

const EmailsMenu: React.FC<EmailsMenuProps> = ({ rows }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterFn = (value: string): void => {
    setSearchParams((prev) => {
      if (!prev.has(value)) {
        prev.set(value, "true");
      } else prev.delete(value);
      return prev;
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>Emails</div>
      <div className={styles.searchBarContainer}>
        <div className={styles.searchBox}>
          <input
            type="text"
            placeholder="Search in all emails..."
            className={styles.searchBoxInput}
          />
          <SearchIcon className={styles.searchIcon} />
        </div>
      </div>
      <div className={styles.optionsContainer}>
        <div className={styles.textContainer}>
          <div className={styles.text}>All emails</div>
        </div>
        <div className={styles.separator}>
          <div className={styles.rectangle} />
        </div>
        <div className={styles.section}>
          <EmailsMenuItem
            text="Unassigned emails"
            number={rows.filter((item) => !item.assignedTo).length}
          />
        </div>
        <div className={styles.separator}>
          <div className={styles.rectangle} />
        </div>
        <div className={styles.section}>
          {Object.entries(STATUS).map(([key]) => (
            <EmailsMenuItem
              text={key}
              key={key}
              number={rows.filter((item) => item.status.includes(key)).length}
              onClick={filterFn}
              searchParams={searchParams}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmailsMenu;
