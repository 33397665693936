import { collection, getDocs, doc, getDoc, updateDoc  } from "firebase/firestore"
import { db } from "../config"

export const fetchEmailIds = async () => {
  const emailsCol = collection(db, "conversations")
  const emailsSnaphot = await getDocs(emailsCol)
  
  const emailsList = emailsSnaphot.docs.map(doc => {
    return ({
      ...doc.data(), 
      id: doc.id, 
      lastModified: doc.data().lastModified?.toDate().toLocaleString("en-gb"),
      createdAt: doc.data().createdAt?.toDate().toLocaleString("en-gb"),
    })
  })
  return emailsList
}

export const fetchEmail = async (docId: string): Promise<any> => {
  const docRef = doc(db, "conversations", docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {...docSnap.data(), id: docSnap.id}
  } else {
    console.log("No such document!");
    return
  }
}

export const updateEmailStatus = async(docId: string, newStatus: string) => {
  const docRef = doc(db, "conversations", docId);
  await updateDoc (docRef, {
    status: newStatus,
    lastModified: new Date()
  })
}

export default db;