import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { onAuthStateChanged } from "firebase/auth";

import { auth } from "../firebase/config";

type Props = {
  children: any;
};

const PrivateRoute = ({ children }: Props) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // const location = useLocation();
  const navigate = useNavigate();
  // UseEffect to remove event listener
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      console.log("checking status");
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        setLoggedIn(true);
        navigate("/");
        console.log(user);
        // ...
      } else {
        // User is signed out
        // ...
        setLoggedIn(false);
        console.log("logged out");
      }
    });
  }, []);

  if (loading) {
    return <div>loading</div>;
  }

  if (loggedIn) {
    // authorized so return child components
    return children;
  }
  return <Navigate to="/login" />;
};
export default PrivateRoute;
