import { Routes, Route } from "react-router-dom";
import { EmailsMenuPage, MessagesPage, LoginPage } from "../pages";
import PrivateRoute from "./PrivateRoute";

const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <EmailsMenuPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/email/:id"
        element={
          <PrivateRoute>
            <MessagesPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default Router;
