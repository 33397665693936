import { Box, Button, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import styles from "./ExitModal.module.css";

interface ExitModalProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  subtitle?: string;
  onConfirm: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const CustomModal: React.FC<ExitModalProps> = ({
  open = false,
  handleClose,
  title,
  subtitle,
  onConfirm,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {title && (
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
        )}
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {subtitle}
        </Typography>
        <div className={styles.buttonsContainer}>
          <Button
            variant="contained"
            className={styles.containedButton}
            onClick={onConfirm}
          >
            OK
          </Button>
          <Button
            variant="outlined"
            className={styles.outlinedButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default CustomModal;
