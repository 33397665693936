// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: process.env.API_key,
//   authDomain: "easyjetaipoc.firebaseapp.com",
//   projectId: "easyjetaipoc",
//   storageBucket: "easyjetaipoc.appspot.com",
//   messagingSenderId: "8999373343",
//   appId: process.env.APP_ID
// };
const firebaseConfig = {
  apiKey: "AIzaSyDUXmWjmzN7wnKPL3J96AdNn-1w1cDoYvQ",
  authDomain: "easyjetaipoc.firebaseapp.com",
  projectId: "easyjetaipoc",
  storageBucket: "easyjetaipoc.appspot.com",
  messagingSenderId: "8999373343",
  appId: "1:8999373343:web:2e5005647b99c590cd232a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);